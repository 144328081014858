import { getFilteredGradesAPI, getGradesAPI } from "../../service/grades";
import {
  SET_GRADES,
  LOADING_GRADES,
  LOADING_FILTERED_GRADES,
  SET_FILTERED_GRADES,
} from "../constants";
import { constructSelectedItemApiParams } from "../../pages/accountPage/utils";

export const loadingGrades = () => ({
  type: LOADING_GRADES,
});

export const setGrades = (grades) => ({
  type: SET_GRADES,
  payload: grades,
});

export const getGradesFromAPI = (lng) => (dispatch, getState) => {
  const state = getState();
  const { url, body } = constructSelectedItemApiParams(
    "grade",
    state.user.data,
    lng
  );
  dispatch(loadingGrades());

  getGradesAPI(url, body).then((grades) => {
    dispatch(setGrades(grades));
  });
};

export const loadingFilteredGrades = () => ({
  type: LOADING_FILTERED_GRADES,
});

export const setFilteredGrades = (grades) => ({
  type: SET_FILTERED_GRADES,
  payload: grades,
});

export const getFilteredGradesFromAPI = (url, body) => (dispatch, getState) => {
  dispatch(loadingFilteredGrades());
  const state = getState();

  getFilteredGradesAPI(url, body, state).then((grades) => {
    dispatch(setFilteredGrades(grades));
  });
};
