import React, {useState} from "react";
import Account from "../account";
import './style.css'
import {TextInput} from "../../components/UI/TextInput";
import {Button} from "../../components/UI/Button";
import {ResendCode} from "../../components/resendCode";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {loginUser, saveUserToken} from "../login/utils";
import {loginByPhoneURI, loginURI} from "../../shared/mockData";
import {getUserFromAPI} from "../../store/actions/user";
import {useTranslation} from "react-i18next";
import {LanguageChanger} from "../../components/languageChanger";

const ConfirmAccount = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const signUpForm = JSON.parse(localStorage.getItem('signUpForm'))
    const {registrationMethod, email, phoneNumber, phoneNumberCountryCode} = signUpForm
    const text = registrationMethod === "Phone" ? "toPhone" : "toEmail"
    const type = registrationMethod === "Phone" ? "aNumber" : "anEmail"
    const phoneNumberAll = phoneNumber && phoneNumberCountryCode ? `+${phoneNumberCountryCode} ${phoneNumber}` : null
    const [code, setCode] = useState('')
    const [error, setError] = useState('')


    const handleChange = () => {
        navigate(-1)
    }

    const resendCode = () => {
        const sendUrl = registrationMethod === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendConfirmationEmail/${email}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/SendConfirmationSMS/${phoneNumberCountryCode}/${phoneNumber}`
        axios.put(
            sendUrl
        ).then((response) => {
            return response
        })
    }

    const signIn = () => {
        const url = registrationMethod === "Email" ? loginURI : loginByPhoneURI
        const body = registrationMethod === "Email" ?{
            email: email,
            password: signUpForm.password,
        } : {
            phoneNumberCountryCode: phoneNumberCountryCode,
            phoneNumber: phoneNumber,
            password: signUpForm.password
        };
        loginUser(url, body)
            .then((res) => {
                if (res.data.accepted) {
                    return res.data;
                }
            })
            .then(saveUserToken)
            .then(getUserFromAPI)
            .then(() => navigate('/email-send'))
            .catch(() => {
                setError("Տեղի է ունեցել սխալ");
            })
    };
    const handleConfirm = () => {
        let body = new FormData();
        body.append("email", signUpForm.email);
        body.append("password", signUpForm.password);
        body.append("firstName", signUpForm.firstName);
        body.append("lastName", signUpForm.lastName);
        body.append("specialityId", signUpForm.specialityId);
        body.append("phoneNumber", signUpForm.phoneNumber);
        body.append("PhoneNumberCountryCode", signUpForm.phoneNumberCountryCode)
        body.append("RegistrationMethod", registrationMethod)
        const url = registrationMethod === "Email" ?
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmEmail/${email}/${code}` :
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ConfirmPhoneNumber/${phoneNumberCountryCode}/${phoneNumber}/${code}`
        if (code.length) {
            axios.post (url)
                .then ((res) => {
                    if (res.data.accepted) {
                        axios.post(`${process.env.REACT_APP_IDENTITY_URL}/api/User/SignUp`, body)
                            .then((resp) => {
                                if(resp.data.accepted) {
                                   localStorage.removeItem("forBackValues");
                                   signIn()
                                }
                            })
                            .catch((err) => console.log(err))
                    } else {
                        setError (t('errors.enterValidConfirmCode'))
                    }
                })
            }
         else {
            setError (t('errors.enterConfirmCode'))
        }
    }
  return (
      <Account
        onlyBG={true}>
          <div className='language-container'>
              <LanguageChanger />
          </div>
          <div
          className={"container"}>
              <div
              style={{margin: '0 0 40px 0',textAlign:'center'}}>
              <span
                className={"check-text"}
              >
                  {t('confirmationCodeSentTo')}
              </span>
              <span
                className={"check-text"}>
                  {registrationMethod === 'Email' ?  ` ${email} ` : `${phoneNumberAll} `}
              </span>
              <span
                className={"check-text"}>
                  {t(`${text}`)}
              </span>
              </div>
              <div
              className={'confirme-code-box'}>
                  <TextInput
                      onChange={e => setCode(e.target.value)}
                      hasError={error}
                      placeholder={`${t('verificationCode')} *`}/>
              </div>
              <Button
                  className="account-submit registrate-btn"
                  action={handleConfirm}
                  style={{height: '60px'}}
                  text={t("register")}
              />
              <button
                  onClick={handleChange}
                  style={{margin: '15px 0'}}>
                  <span
                    className={'changePhone'}>
                    {t('change')} {t(type)}
                  </span>
              </button>
              <ResendCode
              action={resendCode}/>
          </div>
      </Account>
  )
}

export default ConfirmAccount
