import axios from "axios";
const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data?.items || data
    return []
}
export const getMapStyles = (id, mapStyles,list,isLight) => {
    const baseMapGroupHasInSettings = list.findIndex(el=> id === el.mapTypeId) !== -1
    if(!baseMapGroupHasInSettings) return  mapStyles.map(el=> {
        el.hide = false
        if(el.isMain) {
            el.active = el.lightIsDefault ? 'light' : 'dark'
        }
        return el
    })
    return mapStyles.reduce((acum,style) => {
        const findStyle = list.find(item => style.id === item.mapTypeId);
        let activeStyle = false
        if(findStyle){
            style.hide = false
            if(style.id === id){
                if(isLight !== undefined){
                    activeStyle = isLight ? 'light' : 'dark'
                }else{
                    activeStyle = style.id === id ? 'light' : 'dark'
                }
            }
            if(activeStyle){
              style.active = style[activeStyle] ? activeStyle : 'light'
            }else{
              style.active = false
            }

            acum.push(style)
        }
        return acum
    },[])
}
export const setupMapStyles = (props,lng) => {
    const body = {"page":1,"perPage":1500,...props}
    return  axios.post(`${process.env.REACT_APP_GIS_URL}/BaseMapGroup/DataProvider/${lng}`,body)
        .then(extractDataByStatus)
}
export const setupMapExtraLayers = (props) => {
    const body = {"page":1,"perPage":1500,...props}
    return  axios.post(`${process.env.REACT_APP_GIS_URL}/BaseMapStyles/DataProvider`,body)
        .then(extractDataByStatus)
}
export const setupBaseMapConfig = () => {
    return  axios.get(`${process.env.REACT_APP_GIS_URL}/StyleConfigurations`)
        .then(extractDataByStatus)
}


export const getMapStylesDark = (id, mapStyles) => {
    return mapStyles.map((style) => {
        style.active = style.id === id ? style.active === 'light' ? 'dark' : 'light' : false
        return style
    })
}

export const changeMapStylesList = (mapStyles, initialStyles) => {
    const data = [];
    if (!Array.isArray(mapStyles)) {
        return initialStyles
    }
    const mainStyle = mapStyles.find(el=>el.isMain).mapTypeId
    const activeStyle = mapStyles.find(item => mainStyle === item.mapTypeId);
     const result = initialStyles.reduce((acum,style) => {
            const findStyle = mapStyles.find(item => style.id === item.mapTypeId);
            if(findStyle){
                style.hide = true
                if(!window.scrrenShot || !activeStyle){
                    style.active = false
                    if(mainStyle === style.id){
                        style.active = style?.lightIsDefault ? 'light' : 'dark'
                    }
                }
                if (findStyle) {
                    style.hide = false
                }
                acum.push(style)
            }
            return acum
     },[]);
    return result
}
