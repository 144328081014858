import mapboxgl from "mapbox-gl";
import i18n from "i18next";
export const MAP_TOOLBOX_KEYS = {
    DRAWER: "drawer",
    RULER: "ruler",
    LINE_DRAW: "lineDraw",
    ERASER: "eraser",
    HEIGHT: "height",
    COORDS: "coords",
    WIDTH_LENGTH: "widthLength",
    SCALE: "scale",
    UNDO: "undo",
    REDO: "redo",
    DELETE: "delete",
    COMMENT_BOX: "commentBox",
    POLYGON_DRAW: "polygonDraw",
    STICKY_NOTES: "stickyNotes",
}

export const undoRedoDataKeys = [MAP_TOOLBOX_KEYS.DRAWER, MAP_TOOLBOX_KEYS.RULER];

export const MAP_TOOLBOX_ITEMS = [
    {
        id:1,
        title:"Մատիտ",
        key:MAP_TOOLBOX_KEYS.DRAWER,
    },
    {
        id:2,
        title:"Քանոն",
        key:MAP_TOOLBOX_KEYS.RULER,
    },
    {
        id:3,
        title:"Ռետին",
        key:MAP_TOOLBOX_KEYS.ERASER,
    },
    {
        id:4,
        title:"Line Draw",
        key:MAP_TOOLBOX_KEYS.LINE_DRAW,
    },
    {
        id:5,
        title:"Polygon Draw",
        key:MAP_TOOLBOX_KEYS.POLYGON_DRAW,
    },
    {
        id:6,
        title:"Comment Box",
        key:MAP_TOOLBOX_KEYS.COMMENT_BOX,
    },
    {
        id:7,
        title:"Բարձրություն",
        key:MAP_TOOLBOX_KEYS.HEIGHT,
    },
    {
        id:8,
        title:"Կորդինատային համակարգ",
        key:MAP_TOOLBOX_KEYS.COORDS,
    },
    {
        id:9,
        title:"Աշխ․ լայնություն և երկարություն",
        key:MAP_TOOLBOX_KEYS.WIDTH_LENGTH,
    },
    {
        id:10,
        title:"Մասշտաբ",
        key:MAP_TOOLBOX_KEYS.SCALE,
    },
    {
        id:11,
        title:"Քայլ հետ",
        key:MAP_TOOLBOX_KEYS.UNDO,
    },
    {
        id:12,
        title:"Քայլ առաջ",
        key:MAP_TOOLBOX_KEYS.REDO,
    },
    {
        id:13,
        title:"Ջնջել",
        key:MAP_TOOLBOX_KEYS.DELETE,
    },
    {
        id:14,
        title:"Sticky Notes",
        key:MAP_TOOLBOX_KEYS.STICKY_NOTES,
    },
]

/**
 *
 * @param lng
 * @param lat
 * @returns {Promise<number|number>}
 */
export const getHeightByLngLat = async (lng, lat) => {
    const query = await fetch(
        `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lng},${lat}.json?layers=contour&limit=50&access_token=${process.env.REACT_APP_MAP_TOKEN}`,
        { method: 'GET' }
    );
    if (query.status !== 200) return;
    const data = await query.json();
    // Get all the returned features.
    const allFeatures = data.features;
    // For each returned feature, add elevation data to the elevations array.
    const elevations = allFeatures.map((feature) => feature.properties.ele);
    // In the elevations array, find the largest value.
    let  result = Math.max(...elevations);
    return result === -Infinity ? 0 : result;
}

/**
 *
 * @param decimal
 * @returns {`${number}° ${number}' ${string}"`}
 */
export const decimalToDMS = (decimal) => {
    const degrees = Math.floor(decimal);
    const minutesDecimal = (decimal - degrees) * 60;
    const minutes = Math.floor(minutesDecimal);
    const seconds = (minutesDecimal - minutes) * 60;

    return `${degrees}°${minutes}'${Math.round(seconds)}"`;
}

/**
 *
 * @param zoom
 * @param latitude
 * @returns {number}
 */
// export const  getMapScale = (zoom, latitude) => {
//     const metersPerPixel = (156543.03392 * Math.cos(latitude * Math.PI / 180)) / Math.pow(2, zoom);
//     const scaleBarWidth = 100 * metersPerPixel;
//     return  scaleBarWidth >= 1000 ? Math.round(scaleBarWidth / 1000) + ' կմ' : Math.round(scaleBarWidth) + ' մ';
// }
export const  getMapScale = (map) => {
        const t = i18n.t
        // A horizontal scale is imagined to be present at center of the map
        // container with maximum length (Default) as 100px.
        // Using spherical law of cosines approximation, the real distance is
        // found between the two coordinates.
        const maxWidth =  100;
        const y = map._containerHeight / 2;
        const x = (map._containerWidth / 2) - maxWidth / 2;
        const left = map.unproject([x, y]);
        const right = map.unproject([x + maxWidth, y]);
        const maxMeters = left.distanceTo(right);
        // The real distance corresponding to 100px scale length is rounded off to
        // near pretty number and the scale length for the same is found out.
        // Default unit of the scale is based on User's locale.
       if (maxMeters >= 1000) {
            return Math.round(maxMeters / 1000 ) + ' ' + t('km')
        } else {
           return  Math.round(maxMeters) + ' ' +  t('m')
        }

}
