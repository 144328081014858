import {log} from "react-zlib-js";

export const constructSelectedItemApiParams = (type, fields, lng) => {
  const obj = {
    countrie: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/SchoolCountry/DataProvider/1`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
      },
    },
    region: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/State/DataProvider/1`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "state.country.id": fields["country"]
              ? String(fields["country"].value)
              : null,
        },
      },
    },
    state: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/City/DataProvider/1`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "state.id": fields["region"]
              ? String(fields["region"].value)
              : null,
        },
      },
    },
    school: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/School/DataProvider/1`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "city.id": fields["state"]
            ? String(fields["state"].value)
            : null,
        },
      },
    },
    subject: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Subject/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
        },
      },
    },
    grade: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Grade/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
        },
      },
    },
  };

  return obj[type];
};

export const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

export const santizeSelectedResult = (items) =>
    items.map((value) => ({
      value: value.id,
      label: value.title,
    }));

export const sortItemsByAlphabetical = (items, type) => {
  const newCountries = type === 'countrie' ? items : []
  type === 'countrie' && newCountries.unshift({value: 11, label: 'Հայաստան'})
  return type !== 'countrie' ? items.sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      ) :
      newCountries
}

